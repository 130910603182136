.modal-employee-list {
  .modal-dialog {
    max-width: 800px;
  }

  .modal-body {
    max-height: 600px;
    overflow-y: auto;
  }
}

.employee-list {
  margin-top: 10px;

  .employee-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    border-bottom: 1px solid #ddd;

    &:hover {
      background-color: #eee;
    }

    &.selected {
      background-color: #cce5ff;
      font-weight: bold;
    }

    .employee-email {
      flex-shrink: 0;
      margin-left: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.row.marg-t {
  margin-top: 20px;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}

td {
  font-size: 14px;
  vertical-align: middle;
}

.search-container {
  position: relative;
  margin-bottom: 20px;

  input {
    width: 100%;
    padding-right: 30px;
  }

  .clear-search {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    color: #888;
    font-size: 18px;
    line-height: 1;

    &:hover {
      color: #000;
    }
  }
}